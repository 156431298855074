.button {
  --button-width: auto;
  --button-rounded-border-radius: var(--border-radius-2x);
  --icon-button-icon-width: 18px;
  --icon-button-icon-height: 18px;
  --icon-button-icon-display: none;
  font-size: var(--font-size-body);
  padding: var(--space-2-5x) var(--space-6x);
}

.fluid {
  width: 100%;
}

.icon {
  --icon-button-icon-display: flex;
}
