.marketTrends {
  display: none;
  /* stylelint-disable-next-line media-feature-name-disallowed-list */
  @media (min-width: 1350px) {
    display: inline-flex;
  }
}

.listAProperty {
  display: none;
  @media (--screen-sm) {
    display: inline-flex;
  }
}

.businessSolutions {
  display: none;
  @media (--screen-md) {
    display: inline-flex;
  }
}

.manageRentals {
  display: none;
  @media (--screen-lg) {
    display: inline-flex;
  }
}

.movingCenter {
  display: none;
  @media (--screen-xl) {
    display: inline-flex;
  }
}
